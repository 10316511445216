@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;700&display=swap');

@keyframes slideInFromRight {
	0% {
		transform: translateX(100vw);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes fadein {
	from { opacity: 0}
	to   { opacity: 1}
}

.rounded {
	border-radius: 6px;
}

.shadow-bottom {
	/* box-shadow: 0px 5px 10px 10px #FAA; */
	box-shadow: 0px 5px 10px #FAA, 0px 5px 10px #FAA;
}
.border {
	border: #FAA solid 2px;

}

.container {
	width:60vw;
}

.app {  
	animation: 2s ease-out 0s 1 fadein;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #AFF; 
}

.app {
	overflow-x: hidden;
	font-family: 'Raleway',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	background-color: #AFF; 
	color: #ff8080;
}

.app-header {
	background-image: url("../public/static/media/imgs/microphone-transparent.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 100%;
	display:flex;
	justify-content: center;
	align-items: center;
	height:40vh;
	width:100%;
}


.app-header-title {
	margin-top:10px;
	padding-left:20px;
	padding-right:20px;
	background-color: #FFA;
	display: flex;
	justify-content: center;
}

.app-header-title h1 {
	font-size:72px;
	margin:5px;
}

.tab-menu {
	margin: 40px;
	width:55vw;
	display: flex;
	color: #ff8080;
	z-index: 999;
}

.tab-menu h2 { 
	align-self: center;
	color: #ff8080;
	padding: 5px;
	font-size: 36px;
	margin: 0;
	font-weight: 500;
	padding:10px;
	margin: auto;
	cursor: pointer;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}

.profile-name h3 {
	font-size:2em;
	font-weight: 500;
}

.container {
	margin-top: 20px;
	margin-bottom: 20px;
	background-color: #FFA;
}

.profile-image { 
	margin:40px;
	background-image: url("../public/static/media/imgs/people/jack-city-transparent.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-color: #AFF;
	height:300px;
	width:300px;
}

.app-footer {
	position:relative;
	bottom:0px;
	width:100%;
	padding:5px;
	background-color: #FAA;
	color: #FFF;
}

canvas {
}